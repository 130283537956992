export default {
  technicalSkills: [
    {
      id: 5,
      stack: 'JavaScript',
      level: '100%'
    },
    {
      id: 6,
      stack: 'ReactJS/VanillaJS',
      level: '100%'
    },
    {
      id: 14,
      stack: 'NextJS',
      level: '100%'
    },
    {
      id: 15,
      stack: 'NodeJS',
      level: '100%'
    },
    {
      id: 1,
      stack: 'Java/JavaEE',
      level: '100%'
    },
    {
      id: 2,
      stack: 'Spring Boot',
      level: '100%'
    },
    {
      id: 3,
      stack: 'JPA/Hibernate',
      level: '100%'
    },
    {
      id: 4,
      stack: 'JSP/JSF/Primefaces',
      level: '100%'
    },
    {
      id: 7,
      stack: 'MySQL/Postgre',
      level: '60%'
    },
    {
      id: 8,
      stack: 'Android',
      level: '100%'
    },
    {
      id: 9,
      stack: 'MaterializeCSS/Bootstrap',
      level: '100%'
    },
    {
      id: 18,
      stack: 'TailwindCSS',
      level: '100%'
    },
    {
      id: 10,
      stack: 'HTML&CSS',
      level: '100%'
    },
    {
      id: 11,
      stack: 'Google Cloud/Firebase',
      level: '100%'
    },
    {
      id: 12,
      stack: 'Git/GitHub/Gitlab',
      level: '100%'
    },
    {
      id: 13,
      stack: 'XML/JSON/HTTP/REST API',
      level: '100%'
    }
  ],
  softSkills: [
    {
      attribute: 'Excellent communication skills',
      level: 100
    },
    {
      attribute: 'Ability to work with minimum supervision',
      level: 100
      
    },
    {
      attribute: 'Excellent teamwork and coordination capability',
      level: 100
    },
    {
      attribute: 'Professionalism',
      level: 100
    },
    {
      attribute: 'Fast to adapt in both career and environmental changes',
      level: 100
    },
    {
      attribute: 'Fast learner and able to grasp new concepts quickly',
      level: 100
    },
    {
      attribute: 'Problem solving.',
      level: 100
    },
    {
      attribute: 'Potential team leader in creative and innovative work.',
      level: 100
    }
  ],
  frameworks: ['JavaScript', 'HTML', 'CSS','ReactJS', 'NodeJS', 'NextJS', 'Java', 'Spring Boot', 'Android', 'PostgreSQL' ,'MySQL', 'MongoDB'],
  stacks: [
    {
      id: 1,
      title: 'Web Development',
      description: 'Proficient in building professional web frontend with top frameworks like ReactJS and VanillaJS. Currently leveling up on AngularJS'
    },
    {
      id: 2,
      title: 'API Development',
      description: 'Proficient in builing robust and scalable REST APIs. Skilled in building APIs and web backend with Java Spring Boot and NodeJS.'
    },
    {
      id: 3,
      title: 'UI Design',
      description: 'Using the best design tools like Figma and AdobeXD to design and deliver the best, world class UI designs.'
    }
  ],
  userInfo: {
    pic: 'https://firebasestorage.googleapis.com/v0/b/resume-d43a0.appspot.com/o/edited-hero.jpeg?alt=media&token=73a36d21-07d5-4307-a4f6-522ad2544721',//'https://firebasestorage.googleapis.com/v0/b/resume-d43a0.appspot.com/o/hero-pic.JPG?alt=media&token=2b994a2e-c709-48a8-8e8d-c2ccb549232c',
    address: 'Nairobi, Kenya',
    email: 'amosmutai04@gmail.com',
    phone: '+254 72X XXX XXX',
    title: 'Software Developer',
    name: 'Amos Mutai',
    bio:
    "A natural leader and a master problem solver. That's how my colleagues and friends describe me. Personally, I would describe myself as an ambitious and visionary person who works hard to achieve my goals. I find building software programs really fascinating and fun"
      // 'Amos Mutai is a software developer whose mission is to help companies solve their complex problems in the simplest way possible in various business areas. I care about clean, robust code and use agile techniques to deliver tested and high-quality solutions. I enjoy venturing into the unknown and providing companies with the edge they need with software.'
  },
  stories: {
    experiences: [
      {
        id: 4,
        title: 'Software Developer',
        timeline: 'Jan 2022 – Present • 1yr 7 mos',
        company: 'Data Integrated Limited',
        description: `
        <ul>
        <li>Assumed the role of software developer.</li>
        <li>Participate in product conception, design, development, delivery and reviews.</li>
        <li>Attend stakeholder meetings for planning, review and delivery of the product developed.</li>
        <li>Responsible for the design and implementation frontend applications with ReactJS and NextJS.</li>
        <li>Responsible for the design and implementation backend features with NodeJS.</li>
        <li>Responsible for documenting the software solutions developed.</li>
        <li>Collaborating with peers and product stakeholders in a fast-paced distributed team.</li>
        <li>Mentor, code reviews, and plan and delegate assignments amongst colleagues.</li>
        </ul><br />
        <!--- <p><i>Skills;</i> JavaScript/ES6, ReactJS, Redux, Postgres, Sass, Nodejs, ExpressJS, Python/Flask, Python/Django, Jest and Enzyme.</p> -->`
      },
      {
        id: 3,
        title: 'Software Developer',
        timeline: 'June 2021 – Dec 2021 • 7 mos',
        company: 'MSpace Solutions Limited',
        description: `
        <ul>
        <li>Assumed the role of software developer in our instituion.</li>
        <li>Responsible for design and implementation frontend features with JSF/JSP/Primefaces and JavaScript.</li>
        <li>Responsible for design and implementation backend features with Java/JavaEE.</li>
        <li>Responsible for design and implementation MySQL database and Hibernate Query Language</li>
        <li>Collaborating with peers and stakeholders in a fast-paced distributed team.</li>
        <li>Mentor, code reviews, and plan and delegate assignments.</li>
        <li>Design of a backend REST API with Spring Boot, Java/JavaEE.</li>
        </ul><br />
        <!--- <p><i>Skills;</i> JavaScript/ES6, ReactJS, Redux, Postgres, Sass, Nodejs, ExpressJS, Python/Flask, Python/Django, Jest and Enzyme.</p> -->`
      },
      {
        id: 2,
        title: 'IT System Administrator',
        timeline: 'March 2021 – May 2021 • 3 mos',
        company: 'Kenya Power',
        description: `Responsibilities <br />
      <ul>
      <li>Establishing and managing organizations networks where applicable.</li>
      <li>Assist with securing and maintaining the organizations' servers according to best practices.</li>
      <li>Implement IT support tools and solutions in line with user requirements and develop necessary measures to prevent frequent occurring outages.</li>
      <li>Resolve end-user issues related to hardware and software.</li>
      <li>Providing training and support to users for applications.</li>
      <li>Ensuring the security and integrity of all program systems and data.</li>
      <li>Troubleshooting of various network connectivity issues on different categories</li>
      <li>Installation of various application and system software on workstations</li>
      <li>Helping in the formatting of various documents on Microsoft office applications</li>
      <li>Troubleshooting staff applications.</li>
      <li>Configuration of various networking devices.</li>
      <li>Staff User Account management.</li>
      <li>Printer Management.</li>
      <li>Staff Workstation setup.</li>
      <li>Inventory Management</li>
      </ul>`
      }
    ],
    studies: [
      {
        id: 1,
        title: "Bachelor's degree, Information Technology",
        company: 'KCA University',
        timeline: '2017 - 2021',
        description: ''
      }
    ]
  }
};
